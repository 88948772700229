import CARDS from "./cards.js";
import { debouncer } from "./utils.js";

(function cardsAnonymous() {

	const DomController = {
		DOM_STRINGS: {
			cursoTemplate_select: '[data-curso-template]',
			cardsContainer_select: '[data-curso-cards-container]',
			searchInputSelector_select: '[data-search]',
			cardId_select: '[data-card-id]',
			header_select: '[data-header]',
			imgCourse_select: '[data-course-img]',
		},
		selectElementByQuery(selector) {
			return document.querySelector(selector);
		},
		selectAllElementsByQuery(selector) {
			return document.querySelectorAll(selector)
		},
		selectElementById(id) {
			return document.getElementById(id)
		}

	}

	const CoursesCardController = {
		courses: [],
		showAll: false,
		courseCardTemplate() {
			return this.selectElementByQuery(this.DOM_STRINGS.cursoTemplate_select);
		},
		courseCardContainer() {
			return this.selectElementByQuery(this.DOM_STRINGS.cardsContainer_select);
		},
		cardBuilder(curso) {
			const card = this.courseCardTemplate().content.cloneNode(true).children[0];
			const courseLink = card.querySelector('[data-course-link]')
			const cardId = card.querySelector(this.DOM_STRINGS.cardId_select);
			const header = card.querySelector(this.DOM_STRINGS.header_select);
			const imgCourse = card.querySelector(this.DOM_STRINGS.imgCourse_select);
			// const body = card.querySelector("[data-body]");
			//Adding unique identifier to each card
			cardId.setAttribute("data-modal-target", curso.id);
			header.textContent = curso.title;
			// body.textContent = curso.description.substring(0, 168) + " ...";
			imgCourse.src = curso.image;

			//checks if the course already has an external page to redirect. If false disables the link 
			if (curso.external_link !== '') {

				courseLink.href = curso.external_link;
				courseLink.setAttribute('target', '_blank')
			} else {
				courseLink.removeAttribute('href')
			}

			imgCourse.setAttribute('alt', `Fast Care Academy ${curso.title}`);
			return card
		},


		iObserver: new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					CoursesCardController.showAll = true
					CoursesCardController.courseCardContainer().innerHTML = '';
					CoursesCardController.createCardCourses();

				}
			})
		}, {
			rootMargin: "0px 0px 400px 0px",
			threshold: 1.0
		}),

		loadCourses() {
			/*
			const cardActions = document.querySelector('.card-actions');
			const svgArrow = document.querySelector('.arrow-svg');


			cardActions.addEventListener('click', e => {
				e.preventDefault();
				if (e.target.tagName == 'BUTTON' ||
					e.target.tagName == 'svg' ||
					e.target.tagName == 'path') {

					this.showAll = !this.showAll;

					svgArrow.classList.toggle('rotated');
					this.courseCardContainer().innerHTML = '';
					this.createCardCourses();
					//re-maps over the available cards in the dom. Otherwise modals wont open
				}
			})*/

			const contactanos = document.getElementById('contactanos')
			this.iObserver.observe(contactanos)

		},

		createCardCourses() {
			const searchInput = this.selectElementByQuery(this.DOM_STRINGS.searchInputSelector_select);
			if (this.showAll) {
				this.courses = CARDS.map(curso => {
					let card = this.cardBuilder(curso);
					this.courseCardContainer().append(card);
					return { title: curso.title, element: card };

				});

				this.iObserver.unobserve(contactanos)

			} else {

				this.courses = CARDS.slice(0, 3).map(curso => {
					let card = this.cardBuilder(curso);
					this.courseCardContainer().append(card);
					return { title: curso.title, id: curso.id, element: card };
				});

			}


			//debouncing input value
			const updateDebouncedText = debouncer(text => {
				if (this.showAll === false) {
					this.showAll = true;
					this.courseCardContainer().innerHTML = '';
					this.createCardCourses()
					this.courses.forEach(course => {
						const isVisible = course.title.toLowerCase().includes(text);
						course.element.classList.toggle('hide', !isVisible);
					});
				} else {
					this.courses.forEach(course => {
						const isVisible = course.title.toLowerCase().includes(text);
						course.element.classList.toggle('hide', !isVisible);
					});
				}

			}, 800);

			searchInput.addEventListener("input", e => {
				const value = e.target.value.toLowerCase();
				updateDebouncedText(value);

			});


		}
	}

	//behavior delegation
	Object.setPrototypeOf(CoursesCardController, DomController);
	//creating courses cards
	CoursesCardController.createCardCourses();
	CoursesCardController.loadCourses();


})();